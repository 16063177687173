<template>
  <v-card elevation="1" class="resumo">
    <div class="pa-4 grey lighten-3 font-weight-bold">Carta Resposta</div>
    <hr />
    <div class="body pa-4">
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-text-field
          v-model="formData.titulo"
          placeholder="Título da carta"
          outlined
          dense
          label="Título da carta"
          :readonly="viewOnly"
          required
          :rules="rules.genericRules"
        >
        </v-text-field>
        <v-textarea
          v-if="solicitacao.mesa.nome === 'Informações científicas'"
          outlined
          label="Resumo para o representante"
          v-model="formData.resumo"
          rows="3"
          :readonly="viewOnly"
        ></v-textarea>
        <!-- <v-row> -->
        <!-- <v-col cols="6"> -->
        <v-combobox
          v-model="formData.tags"
          :items="listTags"
          hide-no-data
          hide-selected
          item-text="nome"
          item-value="id"
          label="Tags"
          placeholder="Tags"
          outlined
          dense
          :readonly="viewOnly"
          multiple
          small-chips
          required
          :rules="rules.multiSelectRules"
          :deletable-chips="!viewOnly"
        ></v-combobox>
        <!-- <v-autocomplete
              v-model="formData.artigos"
              :items="listArtigos"
              hide-no-data
              hide-selected
              item-text="titulo"
              item-value="id"
              label="Artigos"
              placeholder="Artigos"
              outlined
              dense
              :readonly="viewOnly"
              multiple
              small-chips
              required
              :rules="rules.multiSelectRules"
              :deletable-chips="!viewOnly"
            ></v-autocomplete> -->
        <!-- </v-col>
          <v-col cols="6"> -->
        <v-combobox
          v-model="formData.categorias"
          :items="listCategorias"
          hide-no-data
          hide-selected
          item-text="nomeCompleto"
          item-value="id"
          label="Categorias"
          placeholder="Categorias"
          outlined
          dense
          :readonly="viewOnly"
          multiple
          small-chips
          required
          :rules="rules.multiSelectRules"
          :deletable-chips="!viewOnly"
        ></v-combobox>
        <v-autocomplete
          v-model="formData.produtos"
          :items="listProdutos"
          hide-no-data
          hide-selected
          item-text="nome"
          item-value="id"
          label="Produtos"
          placeholder="Produtos"
          outlined
          dense
          :readonly="viewOnly"
          multiple
          small-chips
          required
          :rules="rules.multiSelectRules"
          :deletable-chips="!viewOnly"
        ></v-autocomplete>
        <!-- </v-col>
        </v-row> -->
        <v-btn
          class="mb-6"
          depressed
          color="fmq_gray"
          dark
          block
          @click="buscarArtigo"
        >
          <v-icon small> mdi-magnify </v-icon>Buscar Artigos</v-btn
        >

        <v-simple-table class="mb-6 tabela-artigo" v-if="artigos.length > 0">
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">Titulo</th>
                <th class="text-left">Autor</th>
                <th class="text-left">Ano de publicação</th>
                <th class="text-left">Ações</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in artigos" :key="item.id">
                <td>{{ item.titulo }}</td>
                <td>{{ item.autor }}</td>
                <td>{{ item.dataPublicacao }}</td>
                <td>
                  <v-btn
                    small
                    class="mr-2"
                    depressed
                    color="fmq_gray"
                    outlined
                    dark
                    @click="openPDF(item.hash)"
                  >
                    PDF
                  </v-btn>
                  <v-btn
                    small
                    depressed
                    color="red"
                    outlined
                    dark
                    @click="excluirArtigo(item)"
                  >
                    Excluir
                  </v-btn>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>

        <v-row>
          <v-col>
            <p class="caption mb-1">Conteúdo</p>
            <editor
              :disabled="viewOnly"
              v-model="formData.conteudo"
              :init="{
                promotion: false,
                language: 'pt_BR',
                height: 1200,
                menubar: true,
                plugins: [
                  'advlist',
                  'autolink',
                  'lists',
                  'link',
                  'image',
                  'charmap',
                  'print',
                  'preview',
                  'anchor',
                  'searchreplace',
                  'visualblocks',
                  'code',
                  'fullscreen',
                  'insertdatetime',
                  'media',
                  'table',
                  'paste',
                  'code',
                  'help',
                  'wordcount',
                ],
                selector: '#editor',
                menu: {
                  custom: {
                    title: 'FQM',
                    items: 'crm nome especialidade data assinatura',
                  },
                },
                menubar: 'file edit view insert format tools table custom',
                setup: function (editor) {
                  editor.ui.registry.addMenuItem('crm', {
                    text: 'CRM',
                    onAction: function () {
                      menuItems('CRM');
                      editor.insertContent('[CRM]');
                    },
                  });
                  editor.ui.registry.addMenuItem('nome', {
                    text: 'Nome',
                    onAction: function () {
                      menuItems('Nome');
                      editor.insertContent('[Nome]');
                    },
                  });
                  editor.ui.registry.addMenuItem('especialidade', {
                    text: 'Especialidade',
                    onAction: function () {
                      menuItems('Espec');
                      editor.insertContent('[Especialidade]');
                    },
                  });
                  editor.ui.registry.addMenuItem('data', {
                    text: 'Data',
                    onAction: function () {
                      menuItems('Data');
                      editor.insertContent('[Data]');
                    },
                  });
                  editor.ui.registry.addMenuItem('assinatura', {
                    text: 'Assinatura',
                    onAction: function () {
                      menuItems('Assinatura');
                      editor.insertContent('[Assinatura]');
                      //const logoUrl = 'https://pfarma.com.br/images/empresa/fqm-farmoquimica.jpg'
                      //const nome = $store.getters.getName
                      //editor.insertContent(`<b>` + nome + `</b>` + `<p>`)
                      //const style = 'width=70px; height=70px'
                      //editor.insertContent(`<img src=${logoUrl} style=${style}>`)
                      //editor.insertContent(`<p>`)
                    },
                  });
                },
                toolbar:
                  'undo redo | formatselect | bold italic backcolor | \
                             alignleft aligncenter alignright alignjustify | \
                             bullist numlist outdent indent | removeformat | help',
              }"
            />
            <v-btn
              v-if="!viewOnly"
              small
              depressed
              color="fmq_gray"
              dark
              class="mt-4"
              @click="send(false)"
              :loading="loading"
            >
              {{
                $route.name === "CartasRespostaEditar"
                  ? "Editar Carta"
                  : "Salvar Carta"
              }}
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </div>
  </v-card>
</template>

<script>
import { rules } from "@/utils/rules.js";
import Editor from "@tinymce/tinymce-vue";
export default {
  name: "SolicitacaoCartaResposta",
  components: {
    editor: Editor,
  },
  props: {
    solicitacao: {
      type: Object,
      default: () => {},
    },
    listTags: {
      type: Array,
      default: () => [],
    },
    listCategorias: {
      type: Array,
      default: () => [],
    },
    listArtigos: {
      type: Array,
      default: () => [],
    },
    listProdutos: {
      type: Array,
      default: () => [],
    },
    artigos: {
      type: Array,
      default: () => [],
    },
    modelo: {
      type: Object,
      default: () => {},
    },
    viewOnly: {
      type: Boolean,
      default: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    aprove: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    formData: {
      titulo: null,
      resumo: null,
      conteudo: null,
      categorias: [],
      tags: [],
      artigos: [],
      produtos: [],
      solicitacoes: null,
      modelosId: null,
    },
    rules: rules,
    valid: true,
  }),
  created() {
    this.formData.produtos[0] = this.solicitacao.produto.id;
    this.formData.solicitacoesId = this.solicitacao.id;
    if (this.viewOnly || this.solicitacao.carta) {
      this.formData.titulo = this.solicitacao.carta.titulo;
      this.formData.resumo = this.solicitacao.carta.resumo;
      this.formData.conteudo =
        this.solicitacao.status === "Finalizado"
          ? this.solicitacao.carta.conteudoProcessado
          : this.solicitacao.carta.conteudo;
      this.formData.categorias = this.solicitacao.carta.categorias;
      this.formData.tags = this.solicitacao.carta.tags;
      // this.formData.artigos = this.solicitacao.carta.artigos.map(
      //   (item) => item.id
      // );
      this.formData.artigos = this.artigos;
    }
  },
  methods: {
    validate() {
      this.$refs.form.validate();
    },
    async send(auto) {
      const categorias = [];
      this.formData.categorias.forEach((categoria) => {
        if (categoria.id) {
          categorias.push(categoria.id);
        } else {
          categorias.push(categoria);
        }
      });

      this.formData.categorias = categorias;

      const tags = [];
      this.formData.tags.forEach((tag) => {
        if (tag.id) {
          tags.push(tag.id);
        } else {
          tags.push(tag);
        }
      });

      this.formData.tags = tags;

      const artigos = [];
      this.artigos.forEach((artigo) => {
        if (artigo.id) {
          artigos.push(artigo.id);
        } else {
          artigos.push(artigo);
        }
      });

      this.formData.artigos = artigos;

      this.formData.produtos;

      if (auto) {
        this.$emit("sendAuto", this.formData);
      } else {
        if (this.formValid) {
          this.$emit("send", this.formData);
        }
      }
    },
    buscarArtigo() {
      this.$emit("buscarArtigo");
    },
    excluirArtigo(item) {
      this.$emit("excluirArtigo", item);
    },
    openPDF(hash) {
      window.open(
        process.env.VUE_APP_API + "donwload/artigos/" + hash,
        "_blank"
      );
    },
    menuItems(item) {
      return item;
    },
  },
  computed: {
    formValid() {
      return this.$refs.form.validate();
    },
  },
  watch: {
    modelo() {
      this.formData.titulo = this.modelo.titulo;
      this.formData.resumo = this.modelo.descricao;
      this.formData.conteudo = this.modelo.conteudo;
      this.formData.categorias = this.modelo.categorias;
      this.formData.tags = this.modelo.tags;
      this.formData.artigos = this.modelo.artigos;
      this.formData.modelosId = this.modelo.id;
    },
    aprove(value) {
      if (value) {
        this.send(true);
      }
    },
  },
};
</script>
<style scoped>
.info-resumo {
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 6px;
  margin-right: 16px;
  padding: 16px;
}
.d-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.tabela-artigo {
  border: thin solid rgba(0, 0, 0, 0.12);
  border-radius: 4px;
}
</style>
